<template>
  <div class="information">
    <div class="information-header center-noflex">
      <div class="header-title">信息公示</div>
      <div class="font-first">汇聚国内优质文化IP版权提供IP交易与授权</div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.information-header {
  height: 400px;
  // background: rgb(6, 79, 200);
  background:url("../../assets/information/information.png");
  background-size: 100% 100%;
  color: #ffffff;
  .header-title {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 10px;
  }
  .font-first {
    font-weight: 100;
  }
}
</style>